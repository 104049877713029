
import { defineComponent, ref, watch, onMounted } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import { PlayListRule } from '@/core/data/playListRule';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import moment from 'moment';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default defineComponent({
	name: 'video-content-modal',
	props: {
		id: String,
		selectedCustomer: String
	},
	components: {
		Datatable
	},
	setup(props, { emit }) {
		const id = props.id;
		const loading = ref<boolean>(true);
		const paginationHeader = ref<any>(null);
		const pagingFilteration = ref<any>({
			paging: {
				pageNumber: 1,
				pageSize: 10
			},
			filter: {
				searchTerm: '',
				type: ''
			},
			orderBy: {
				orderByField: '',
				orderDesc: false
			}
		});
		const RuleModalRef = ref<null | HTMLElement>(null);

		const tableHeader = ref([
			{
				key: 'checkbox',
				sortable: false
			},
			{
				name: 'Title',
				key: 'name',
				sortable: true
			},
			{
				name: 'Content Type',
				key: 'contentType',
				sortable: true
			},
			{
				name: 'Sort Rule',
				key: 'sortRule',
				sortable: true
			},
			{
				name: 'matchValueToAllFilters',
				key: 'matchValueToAllFilters',
				sortable: true
			}
		]);

		watch(
			() => pagingFilteration.value.paging.pageSize,
			(newValue, oldValue) => {
				console.log('newValue', newValue);
				console.log('oldValue', oldValue);
				getMediaList();
			},
			{ deep: true }
		);

		watch(
			() => pagingFilteration.value.paging.pageNumber,
			(newValue, oldValue) => {
				console.log('newValue', newValue);
				console.log('oldValue', oldValue);
				getMediaList();
			},
			{ deep: true }
		);

		const tableData = ref<Array<PlayListRule>>([]);
		const initMedia = ref<Array<PlayListRule>>([]);

		const getMediaList = () => {
			ApiService.post(`PlayListRule/list`, pagingFilteration.value)
				.then(({ data, headers }) => {
					paginationHeader.value = JSON.parse(headers['x-pagination']);
					// console.log('check', paginationHeader.value);
					tableData.value.splice(0, tableData.value.length, ...data);
					initMedia.value.splice(0, tableData.value.length, ...data);
					loading.value = false;
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const onItemsPerPageChange = itemsPerPage => {
			pagingFilteration.value.paging.pageSize = itemsPerPage;
		};

		const onCurrentChange = currentPage => {
			pagingFilteration.value.paging.pageNumber = currentPage;
		};

		const setSelectedPlayListRule = row => {
            console.log("Rule selected", row)
			emit('setSelectedPlayListRule', row);
			hideModal(RuleModalRef.value);
		};

		onMounted(() => {
			getMediaList();

			var modelElement = <any>(
				document.getElementById('kt_modal_add_play_list_rule_modal')
			);
			if (modelElement) {
				modelElement.addEventListener('hidden.bs.modal', function (event) {
					emit('modalCloseCallback');
				});
			}
		});

		const search = ref<string>('');

		const searchItems = () => {
			console.log('searchItems:', pagingFilteration.value);
			getMediaList();
		};

		const filterType = () => {
			getMediaList();
		};
		return {
			id,
			moment,
			search,
			loading,
			tableData,
			filterType,
			tableHeader,
			paginationHeader,
			pagingFilteration,
			RuleModalRef,
			searchItems,
			onCurrentChange,
			onItemsPerPageChange,
			setSelectedPlayListRule
		};
	}
});
